import { createStore, combineReducers } from "redux";
import firebase from "firebase";
import "firebase/firestore";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer, createFirestoreInstance } from "redux-firestore";
import { reducer as formReducer } from "redux-form";
// Import Custom Reducers Here

const firebaseConfig = {
  apiKey: "AIzaSyAFQkL06wBVGr3kCS9456XVnkNV7Xs5yS0",
  authDomain: "react-portfolio-58cba.firebaseapp.com",
  databaseURL: "https://react-portfolio-58cba.firebaseio.com",
  projectId: "react-portfolio-58cba",
  storageBucket: "react-portfolio-58cba.appspot.com",
  messagingSenderId: "279573463543",
  appId: "1:279573463543:web:78a2f894451e85b75d2ef2",
};

// react-redux-firebase-config
const rrfConfig = {
  userProfile: "users",
  useFirestoreProfile: true, // Firestore instead of Realtime DB
};

// Init firebase
firebase.initializeApp(firebaseConfig);
// Init firestore
firebase.firestore();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer,
  // Add custom reducers here
});

// Create initial state
const initialState = {};

const store = createStore(rootReducer, initialState);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export default store;
